.App {
  text-align: center;
  display: grid;
  grid-template-rows: 5rem auto 6rem;
  height: 100%;
  row-gap: 3rem;
}

.button {
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  padding: 12px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.typing-card {
  width: 55%;
  padding: 40px;
  margin: auto;
  box-shadow: 4px 4px 6px 6px rgba(154, 211, 188, 0.5);
  grid-row-start: 2;
  grid-row-end: 3;
}

.liveResult {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-evenly;
  font-size: 30px;
}

.typing-area {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  max-height: 13.5rem;
  overflow: hidden;
}

.word {
  display: flex;
  min-height: 54px;
}

.letter {
  min-width: 20px;
  max-width: 35px;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 2px;
  letter-spacing: 2px;
  outline: 1px solid #ffffff;
  line-height: normal;
}

.current {
  background: rgba(196, 182, 182, 0.4);
}

.correct {
  background: #d3e6fb;
  color: #004f80;
}

.wrong {
  background: rgba(255, 124, 124, 0.5);
  color: darkred;
}

#wrongLetterVisible {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

#wrongLetterInvisible {
  display: none;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.form-input {
  border-color: #e2e8f0;
  border-radius: 4px;
  border-width: 1px;
  color: #4a5568;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.from-label {
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 600;
}

.from-button {
  background: #4a5568;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.from-button:hover {
  background: #354053;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-header button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a5568;
}

.modal-header button:hover {
  color: #354053;
}
