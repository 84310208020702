.result {
  width: 55%;
  padding: 40px;
  margin: auto;
  box-shadow: 4px 4px 6px 6px rgb(154 211 188 / 50%);
  grid-row-start: 2;
  grid-row-end: 3;
}

.result > section {
  display: flex;
  justify-content: space-evenly;
  font-size: 20px;
}

.result > button {
  margin-top: 1rem;
}
