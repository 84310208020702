.header {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  box-sizing: border-box;
  height: 4rem;
}

.header > div {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  width: 60%;
}
